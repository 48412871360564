var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"tabs"},[_vm._l((_vm.list),function(item,index){return [(item.state)?_c('div',{key:index,staticClass:"tabs-item",class:{
          'tabs-item-active': item.key === _vm.actived,
          'tabs-item-active-next': item.key === (_vm.actived + 1),
          'tabs-item-active-prev': item.key === (_vm.actived - 1)
          },on:{"click":function($event){return _vm.onChange(item)}}},[_c('span',[_vm._v(_vm._s(item.label))])]):_vm._e()]})],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isShow),expression:"!isShow"}],staticClass:"w_tabs"},[_c('div',{staticClass:"tab_box"},[_c('div',{staticClass:"tabs"},[_vm._l((_vm.list),function(item,index){return [(item.state)?_c('div',{key:index,staticClass:"tabs-item",class:{
              'tabs-item-active': item.key === _vm.actived,
              'tabs-item-active-next': item.key === (_vm.actived + 1),
              'tabs-item-active-prev': item.key === (_vm.actived - 1)
              },on:{"click":function($event){return _vm.onChange(item)}}},[_c('span',[_vm._v(_vm._s(item.label))])]):_vm._e()]})],2),_c('div',{staticClass:"buy_box"},[(_vm.btnStateComputed.state === "立即报名")?_c('div',[_c('span',{staticClass:"price-now"},[_vm._v("￥"+_vm._s(_vm.detail.currentPrice))]),(_vm.detail.originalPrice)?_c('span',{staticClass:"price-old"},[_vm._v("￥"+_vm._s(_vm.detail.originalPrice))]):_vm._e()]):_vm._e(),(_vm.detail && _vm.detail.whetherReation === 2)?[(_vm.btnStateComputed.canClick)?_c('button',{staticClass:"buy-btn",on:{"click":function($event){return _vm.confirm(_vm.btnStateComputed.type)}}},[_vm._v(_vm._s(_vm.btnStateComputed.state))]):_vm._e(),(!_vm.btnStateComputed.canClick)?_c('button',{staticClass:"buy-btn buy-btn-gray"},[_vm._v(_vm._s(_vm.btnStateComputed.state))]):_vm._e()]:_vm._e(),(_vm.detail && _vm.detail.whetherReation !== 2)?[_c('button',{staticClass:"buy-btn buy-btn-gray",on:{"click":_vm.ptclassTip}},[_vm._v("配套课程")])]:_vm._e()],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }